import React from 'react';

import { ReactComponent as ThumbsUpDoodle } from '~/assets/icons/thumbs-up-doodle.svg';

import styles from './ClientPraise.module.scss';

export type ClientPraiseData = {
  text: string;
  clientName: string;
  clientPosition: string;
  companyName: string;
  img: string;
};

type Props = {
  data: ClientPraiseData;
};

const ClientPraise: React.FC<Props> = ({ data }) => {
  const { text, clientName, clientPosition, companyName, img } = data;

  return (
    <div className={styles.root}>
      <p className={styles.quote}>&quot;{text}&quot;</p>
      <div className={styles.clientAvatar}>
        <ThumbsUpDoodle className={styles.doodle} />
        <img src={img} alt={clientName} className={styles.img} />
        <div>
          <div className={styles.clientName}>{clientName}</div>
          <div className={styles.clientPosition}>
            {clientPosition}, <b>{companyName}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPraise;
