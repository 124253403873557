import React from 'react';
import { PageProps } from 'gatsby';

import data from '~/data/case-studies/zigzag';
import Layout from '~/components/Layout';
import ProjectHeader from '~/components/ProjectHeader';
import ClientPraise, { ClientPraiseData } from '~/components/ClientPraise';
import ClientDescription, {
  ClientDescriptionData,
} from '~/components/ClientDescription';
import { ProjectDTO } from '~/types';
import ProjectPlatform, {
  ProjectPlatformData,
} from '~/components/ProjectPlatform';
import ProjectExcerpt, {
  ProjectExcerptData,
} from '~/components/ProjectExcerpt';
import ScopeDescription, {
  ScopeDescriptionData,
} from '~/components/ScopeDescription';
import Image from '~/components/Image';

import styles from './ZigZag.module.scss';
import YoutubeVideo from '~/components/YoutubeVideo';
import EstimationCTA from '~/components/EstimationCTA';
import OtherProjects from '../components/OtherProjects';

const ZigZag: React.FC<PageProps> = ({ location }) => {
  const projectHeaderData: ProjectDTO = {
    ...data.generalInfo,
    img: data.generalInfo.img,
  };

  const clientDescriptionData: ClientDescriptionData = {
    textShort: data.client.descriptionShort,
    textLong: data.client.descriptionLong,
    scope: data.scope.types,
    techStack: data.techStack,
  };

  const projectPlatformData: ProjectPlatformData = {
    platforms: data.platforms.types,
    projectName: data.generalInfo.name,
    appStoreUrl: data.generalInfo.appStoreUrl,
    slides: [
      data.platforms.images[0],
      data.platforms.images[1],
      data.platforms.images[2],
    ],
  };

  const projectExcerptData: ProjectExcerptData = {
    text: data.tldr,
  };

  const projectScopeData: ScopeDescriptionData = {
    textShort: data.scope.descriptionShort,
    textLong: data.scope.descriptionLong,
  };

  const clientPraiseData: ClientPraiseData = {
    clientName: data.client.name,
    clientPosition: data.client.position,
    companyName: data.generalInfo.name,
    text: data.client.quote,
    img: data.client.img,
  };

  return (
    <Layout invertNavigation>
      <header className={styles.header}>
        <ProjectHeader data={projectHeaderData} />
      </header>
      <section className={styles.client}>
        <ClientDescription data={clientDescriptionData} />
      </section>
      <section className={styles.platforms}>
        <ProjectPlatform data={projectPlatformData} />
      </section>
      <section className={styles.excerpt}>
        <ProjectExcerpt data={projectExcerptData} />
      </section>
      <section className={styles.work}>
        <ScopeDescription data={projectScopeData} />
      </section>
      <section className={styles.features}>
        <Image
          img={data.featuresImg}
          imgMobile={data.mobileFeaturesImg}
          alt='ZigZag Features'
          className={styles.featuresImg}
        />
      </section>
      <section className={styles.praise}>
        <ClientPraise data={clientPraiseData} />
      </section>
      <section className={styles.video}>
        <YoutubeVideo data={data.mainVideo} />
      </section>
      <section className={styles.cta}>
        <EstimationCTA />
      </section>
      <article>
        <OtherProjects curretPageUrl={location.pathname} />
      </article>
    </Layout>
  );
};

export default ZigZag;
