import { ProjectCategory } from '~/enums';

import zigzagMain from './assets/zigzag.jpg';
import zigzagMainMobile from './assets/zigzag@mobile.jpg';
import zigzagFeatures from './assets/zigzag-features.jpg';
import zigzagFeaturesMobile from './assets/zigzag-features@mobile.jpg';
import zigzagApp1 from './assets/zigzag-app-1.jpg';
import zigzagApp2 from './assets/zigzag-app-2.jpg';
import zigzagApp3 from './assets/zigzag-app-3.jpg';
import zigzagAvatar from './assets/zigzag-avatar.jpg';
import routes from '~/routes';

export const zigzagGeneralInfo = {
  name: 'ZigZag',
  category: ProjectCategory.SOCIAL_MEDIA,
  summary: 'Create viral videos with friends',
  description: 'Unknown', // TODO fill this after main case studies page gets designed
  appStoreUrl:
    'https://apps.apple.com/us/app/zigzag-video-collaboration/id1500854244',
  img: zigzagMain,
  imgMobile: zigzagMainMobile,
  projectPageUrl: routes.CASE_STUDIES_ZIGZAG,
};

const zigzagCaseStudy = {
  generalInfo: zigzagGeneralInfo,
  platforms: {
    types: ['iOS'],
    images: [zigzagApp1, zigzagApp2, zigzagApp3],
  },
  mainVideo: {
    title: 'ZigZag Video Testimonial',
    embedUrl: 'https://www.youtube.com/embed/FKLZRnWpJ5o',
  },
  client: {
    name: 'Ben Jaimen',
    position: 'CEO',
    img: zigzagAvatar,
    quote:
      'I love how professional everything is, they guarantee me high quality. To me, SwingDev are rockstars',
    descriptionShort:
      'Ben Jaimen is an LA-based entrepreneur, as well as a successful singer-songwriter.',
    descriptionLong:
      ' He always saw social media as a gateway to unleash the creativity of people and bring them together through art. Before founding ZigZag, he was working as a manager at Sony Music and Piranha Records. He was also a partner in an angel investment firm that helped some popular global startups.',
  },
  scope: {
    types: ['Design', 'Development'],
    descriptionShort:
      'Imagine singing “Happy Birthday” to your friend with 10 friends online! ZigZag allows you to co-create short videos with friends and share them.',
    descriptionLong:
      '<p>It sports an integrated newsfeed, user profiles, <b>simple video editing features</b>, and a search engine for memes and animated GIF-s. It&apos;s a unique combination of ideas known from <b>Instagram and TikTok</b> with an additional twist.</p><p>We got this project after the initial proof of concept was created by another company, and took care of native mobile development. Our first task was to <b>improve the code quality</b> and work on the API with a back-end team.</p><p>Then, we collaborated with them on expanding the functionality. The latest version of ZigZag works more efficiently than the previous one and introduces many new features.</p>',
  },
  featuresImg: zigzagFeatures,
  mobileFeaturesImg: zigzagFeaturesMobile,
  techStack: [
    'Native iOS (Swift)',
    'High scalability backend with video streaming',
  ],
  tldr:
    'We are co-creating a social media platform for recording videos with friends. The iOS app allows users to shoot and edit videos, gather followers, and find the best videos on a feed.',
};

export default zigzagCaseStudy;
